@import "../../../variables";
@import "bootstrap/scss/mixins/breakpoints";

.up-2-cta {
    .experience-region {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
    }

    &.margins-enabled {
        margin-top: 15px;

        @include media-breakpoint-up(md) {
            margin-top: 32px;
        }

        @include media-breakpoint-up(lg) {
            margin-top: 21px;
        }

        @include media-breakpoint-up(xl) {
            margin-top: 31px;
        }
    }
}
