@import "~base/variables";

$primary: #d2c08a;
$secondary: #8d7045;
$menu-bg: #27251f;
$purina-teal: #357793;
$body-color: $black;
$place-holder: #555;
$grey4: #959595;
$grey1: #f3f3f3;
$error-color: #df2935;
$card-border: #dadada;
$red: #df2935;
$input-active: #475569;
$bg-color: #dadada;
$color-availability: #280;
$button-disable: #bcbcbc;
$button-hover:#b39d5a;
$color-black: #000000;
$color-white: #ffffff;

$color-minsk: #50307F;

$brand-color: #e81c24;
$brand-black: #1E1E1E;
$brand-white: #ffffff;
$brand-border: #f5f5f5;

$text-color: #2b2b2b;
$title-color: #1e1e1e;

$color-title:#8D7045;
$swatch-border: #b39d5a;
$swatch-border-hover: #b39d5a;
$swatch-border-active: #d2c081;
$swatch-border-unselectable: #dadada;
$swatch-border-unselectable-active: #959595;
$link-color: $text-color;

$button-border-radius: rem-calc(4);
$hr-border-color: $primary;
$menu-link: transparent;
$body-bg: $bg-color;

$product-variation-hover:#b39d5a;
$product-variation-active:#d2c08a;
$product-variation-unselectable: #dadada;

$outofstock-background: rgba(0, 0, 0, 0.3); 
$outofstock-color: #ffffff;

$font-antenna-purina: "Antenna Purina", sans-serif;
$font-antenna-purina-condensed: "Antenna Purina Condensed", sans-serif;
$font-antenna-purina-danforth: "Purina Danforth", sans-serif;
$product-font: "Antenna Purina", sans-serif;

$container-max-widths: (
    md: 767px,
    lg: 991px,
    xl: 1200px
);

:root {
    --skin-banner-background-color-1: transparent;
    --skin-menu-color-1: transparent;
    --skin-menu-color-1-invert: transparent;
    --skin-primary-color-1: #{$purina-teal};
    --color-grey1: #{$grey1};
}
